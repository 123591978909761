<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
      <div class="card overflow-hidden">
        <div class="bg-primary">
          <div class="text-primary text-center p-4">
            <h5 class="text-white font-size-20 p-2">Reset Password</h5>
          </div>
        </div>
        <div class="card-body p-4">
          <div class="p-3">
            <b-alert v-model="isShowRes" class="mt-3" variant="secondary" dismissible>
              {{msg}}
            </b-alert>

            <b-form @submit.prevent="tryToReset" class="form-horizontal mt-4">
<!--              <b-form-group id="email-group" label="Email" label-for="email">-->
<!--                <b-form-input id="email" v-model="email" type="email" placeholder="Enter email"-->
<!--                              :disabled="true"></b-form-input>-->
<!--              </b-form-group>-->
              <b-form-group id="email-group" label="Code" label-for="Code">
                <b-form-input id="email" v-model="code" type="text" placeholder="Enter code"
                              :disabled="disabled"></b-form-input>
              </b-form-group><b-form-group id="email-group" label="New Password" label-for="newPassword">
                <b-form-input id="email" v-model="newPassword" type="password" placeholder="Enter new password"
                              :disabled="disabled"></b-form-input>
              </b-form-group><b-form-group id="email-group" label="Confirm Password" label-for="confirmPassword">
                <b-form-input id="email" v-model="confirmPassword" type="password" placeholder="Enter confirm password"
                              :disabled="disabled"></b-form-input>
              </b-form-group>

              <div class="form-group row mb-0">
                <div class="col-12 text-right">
                  <b-button type="submit" variant="primary" class="w-md" :disabled="disabled">
                    <b-spinner small v-if="disabled" style="color:white;"
                               label="Spinning"></b-spinner>
                    Send
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
      <div class="mt-5 text-center">
        <p class="mb-0">
          © {{new Date().getFullYear()}} Any
        </p>
      </div>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {authMethods} from "@/state/helpers";
  import appConfig from "@/app.config";

  /**
   * Forgot password component
   */
  export default {
    page: {
      title: "Reset Password",
      meta: [{name: "description", content: appConfig.description}]
    },
    data() {
      return {
        email: "",
        code:"",
        newPassword:"",
        confirmPassword:"",
        disabled: false,
        restPasswordResponse: '',
        msg: '',
        isShowRes: false
      };
    },
    mounted() {
      if (!this.$route.params.user){
        this.$router.push('/forgot-password')
        return;
      }
      let user = this.$route.params.user;
      this.code = user.forgot_code;
      this.email = user.email;
      console.log("user", this.$route.params.user);
    },
    components: {},
    methods: {
      ...authMethods,
      tryToReset() {
        this.msg = '';
        this.isShowRes = false;
        this.disabled = true;
        if (this.newPassword !== this.confirmPassword){
          this.isShowRes = true;
          this.disabled = false;
          this.msg = "Password not matched"
          return;
        }
        let obj = {
          code:this.code,
          password : this.newPassword,
        }
        this.passwordReset(obj).then(() => {
          this.isShowRes = true;
          this.disabled = false;
          this.msg = "Reset Password link sent at your email"
          this.$router.push('/login');
        }).catch(error => {
          this.disabled = false;
          this.msg = error.response.data.message
          if (this.msg.includes('Failed to authenticate')) {
            this.msg = 'Email not send due to Authentication Failure'
          }
          this.isShowRes = true;
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .logo-admin {
    background: #2e3648 !important;
  }
</style>