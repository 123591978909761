import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    layoutWidth: (state) => state.layoutWidth,
  }),
}

export const employeeComputed = {
  ...mapState('employee', {
    employees: (state) => [state.employees, state.status]
  }),
}

export const employerComputed = {
  ...mapState('employer', {
    employers: (state) => [state.employers, state.status]
  })
}

export const industryComputed = {
  ...mapState('industry', {
    industries: (state) => state.industries
  })
}

export const serviceComputed = {
  ...mapState('service', {
    services: (state) => state.services
  })
}

export const foserviceComputed = {
  ...mapState('foservice', {
    foservices: (state) => state.foservices
  })
}

export const specialityComputed = {
  ...mapState('speciality', {
    specialities: (state) => state.specialities
  })
}

export const subSpecialityComputed = {
  ...mapState('subspeciality', {
    subspecialities: (state) => state.subspecialities
  })
}

export const dashboardComputed = {
  ...mapState('dashboard', {
  dashboard: (state) => [state.stats,state.graph] 
  })
}

export const jobsComputed = {
  ...mapState('jobs', {
    subspecialities: (state) => [state.jobs , state.pagination]
  })
}


export const appSettingsComputed = {
  ...mapState('appSetting', {
    subspecialities: (state) => state.settings
  })
}


export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword','updatedProfile','updatedPassword','passwordResetEmail','passwordReset'])

export const layoutMethods = mapActions('layout', ['changeLayoutType'])

export const employeeMethods = mapActions('employee',['getAllEmployees','toggleStatus','updateStatus','deleteUserDocs','getVerifiedEmployees','getNotVerifiedEmployees','getBlockedEmployees','deleteUserSkills','updateSkillsStatus','deleteEmployeeUser'])

export const employerMethods = mapActions('employer',['getAllEmployers','toggleStatus','deleteEmployerUser'])

export const industryMethods = mapActions('industry',['getAllIndustries','addIndustry','updateIndustry','deleteIndustry'])

export const serviceMethods = mapActions('service',['getAllServices','addService','updateService','deleteService'])

export const foServiceMethods = mapActions('foservice',['getAllFoServices','addFoService','updateFoService','deleteFoService'])

export const specialityMethods = mapActions('speciality',['getAllSpecialities','addSpeciality','updateSpeciality','deleteSpeciality'])

export const subSpecialityMethods = mapActions('subspeciality',['getAllSubSpecialities','addSubSpeciality','updateSubSpeciality','deleteSubSpeciality'])

export const dashboardMethods = mapActions('dashboard',['getAllStats','getGraphData'])

export const jobsMethods = mapActions('jobs',['getAllJobs','cancelEmployerJob'])

export const AppSettingMethods = mapActions('appSetting',['getAllAppSetting','addAppSetting','updateAppSetting','deleteAppSetting'])
